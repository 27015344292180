<template>
  <div class="form-item">
    <div class="form-label is-required">{{ fieldLabel }}</div>
    <Field :name="fieldName" v-model="file" v-slot="{ value: currentFile, resetField, errors }">
      <div class="form-upload" :class="{ 'is-active': currentFile, 'is-invalid': errors.length > 0 }">
        <!-- 本地上傳 -->
        <template v-if="fileStatus(currentFile) === 'local'">
          <i class="file-icon icon" :class="fileClass(currentFile.type)"></i>
          <span>{{ currentFile.name }}</span>
          <span>{{ Math.round(currentFile.size / 1024 / 1024 * 10) / 10 }} MB</span>
        </template>
        <!-- server 載入 -->
        <template v-if="fileStatus(currentFile) === 'server'">
          <i class="file-icon icon icon-file"></i>
          <span>已上傳檔案</span>
          <span>點此重新上傳檔案</span>
        </template>
        <!-- 無檔案 -->
        <template v-if="fileStatus(currentFile) === 'empty'">
          <i class="file-icon icon icon-upload"></i>
          <span class="tag">選擇檔案</span>
          <span>請將檔案拖曳至此，或是點擊上傳</span>
        </template>
        <label :name="fieldName" class="form-label">上傳檔案</label>
        <input type="file" ref="fileInput" @change="onFileInputChange" />
        <button type="button" v-if="fileStatus(currentFile) === 'local'" class="form-upload-reset" @click="cleanFileInput(resetField)">
          <i class="icon icon-close" aria-hidden="true"></i>
        </button>
      </div>
      <!-- preview link -->
      <!-- 本地上傳 -->
      <a
        :href="fileInfo?.previewURL || createPreviewURL(currentFile)"
        class="form-upload-previewBtn is-uploaded mt-1"
        target="_blank"
        v-if="fileStatus(currentFile) === 'local'"
        >檢視檔案 <i class="icon icon-external"></i
      ></a>
      <!-- server 載入 -->
      <a
        :href="currentFile"
        class="form-upload-previewBtn is-uploaded mt-1"
        target="_blank"
        v-if="fileStatus(currentFile) === 'server'"
        >檢視檔案 <i class="icon icon-external"></i
      ></a>
    </Field>

    <div class="form-promp is-invalid">
      <ErrorMessage :name="fieldName" />
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { Field, ErrorMessage } from 'vee-validate';

export default {
  props: {
    limitSize: Number,
    limitType: Array,
    fieldLabel: String,
    fieldName: {
      type: String,
      default: '',
    },
    readonlyInfo: {
      type: Object,
      default: null,
    },
  },
  components: {
    Field,
    ErrorMessage,
  },
  data() {
    return {
      isLoading: false,
      file: null,
    };
  },
  methods: {
    onFileInputChange(e) {
      const file = e.target?.files[0];
      this.file = file || null;
    },
    fileClass(type) {
      const fileExtension = type.includes('/') ? type.split('/')[1].toLowerCase() : type;
      if (fileExtension === 'jpg' || fileExtension === 'jpeg') return 'icon-file-jpg';
      if (fileExtension === 'pdf') return 'icon-file-pdf';
      return 'icon-file';
    },
    // 上傳
    async handleUpload() {
      const formData = new FormData();
      formData.append('files', this.picFile);
      formData.append('agree', this.picAgree);

      const res = await this.$api.uploadFile(formData);
      this.file = res.data.file;
    },
    cleanFileInput(resetCallback) {
      resetCallback({ value: null });
      this.file = null;
    },
    fileStatus(currentFile) {
      const checkStatus = _.cond([
        [(file) => file && !_.isString(file), () => 'local'],
        [(file) => file && _.isString(file), () => 'server'],
        [() => true, () => 'empty'],
      ]);

      return checkStatus(currentFile);
    },
    createPreviewURL(file) {
      return URL.createObjectURL(file);
    },
  },
  computed: {
    fileInfo() {
      function createFileinfo(file) {
        const { name, size, type } = file;
        const previewURL = file.previewURL ? file.previewURL : URL.createObjectURL(file);

        return {
          name,
          type,
          size: _.round(size / 1024 / 1024, 2),
          previewURL,
        };
      }

      if (_.isNull(this.file)) return null;
      if (_.isString(this.file)) return { previewURL: this.file };
      return createFileinfo(this.file);
    },
  },
};
</script>
