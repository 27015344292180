<template>
  <div class="form-item">
    <div class="form-label" :class="{'is-required': isRequired}">{{fieldLabel}}</div>
    <div class="form-radios">
      <div class="form-radio" v-for="option in formateOptions.options" :key="option.id">
        <label :for="`${fieldName}${option.id}`">
          <Field
            :name="fieldName"
            type="radio"
            :value="option.id"
            :id="`${fieldName}${option.id}`"
            @change="selectOption"
          />
          <span></span>
          {{option.name}}
        </label>
      </div>
      <div class="form-radio-withInput" v-if="hasOtherOption">
        <div class="form-radio">
          <label :for="`${fieldName}${formateOptions.other.id}`">
            <Field
              :name="fieldName"
              type="radio"
              :value="formateOptions.other.id"
              :id="`${fieldName}${formateOptions.other.id}`"
            />
            <span></span>
            {{formateOptions.other.name}}
          </label>
        </div>
        <FormInput :field-name="fieldNameOther" :is-required="false" style="padding: 0"/>
      </div>
    </div>
    <div class="form-promp is-invalid">
      <ErrorMessage :name="fieldName" />
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { Field, ErrorMessage } from 'vee-validate';
import FormInput from '@/components/Input/FormInput.vue';

export default {
  props: {
    isRequired: {
      type: Boolean,
      default: true,
    },
    fieldName: String,
    fieldLabel: String,
    fieldHelper: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    hasOtherOption: {
      type: Boolean,
      default: false,
    },
    fieldNameOther: String,
  },
  components: {
    Field,
    ErrorMessage,
    FormInput,
  },
  computed: {
    formateOptions() {
      const { options } = this;

      if (this.hasOtherOption) {
        return {
          options: _.take(options, options.length - 1),
          other: _.last(options),
        };
      }
      return { options };
    },
  },
  methods: {
    selectOption() {
      this.$emit('optionSelected');
    },
  },
};
</script>
