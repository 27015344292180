<template>
  <div v-if="step === currentStep">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    step: Number,
  },
  inject: ['currentStep'],
};
</script>
